.ant-layout-header{
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    /* border-bottom: 1px solid rgba(0,0,0,.1); */
}
.ant-row{
    max-width: 1440px;
    margin: 0px auto !important;
}
.ant-menu-horizontal{
    justify-content: end;
    /* border-bottom: 1px solid rgba(0,0,0,.1); */
    border-bottom: none;
}
.btn-drawer{
    float: right;
}
.ant-menu{
    color: var(--e-color-primary);
    width: 100%;
}
.ant-menu .ant-menu-title-content{
    width: 100%;
}
.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title{
    padding-right: 17px;
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow{
    display: block;
    right: 0px;
    transform: rotate(90deg);
    color: var(--e-color-primary);
}
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover {
    color: var(--e-color-primary);
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after {
    border-bottom: 2px solid var(--e-color-orange);
}
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, 
.ant-menu-submenu-popup:hover>.ant-menu-submenu-arrow{
    transform: rotate(-90deg) !important;
}
[aria-expanded="true"] .ant-menu-submenu-arrow{
    transform: rotate(-90deg) !important;
    color: var(--e-color-orange);
}
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow{
    color: var(--e-color-orange);
}
.ant-menu-item a:hover, .ant-menu-light .ant-menu-item:hover, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-light .ant-menu-item-active{
    color: var(--e-color-primary);
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-tabs-tab-btn:focus{
    color: var( --e-color-primary );  
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after{
    border-bottom: 2.2px solid var( --e-color-primary );
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, 
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
    color: var( --e-color-primary );
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right-color: transparent;
}
.ant-menu{
    box-shadow: none !important;
}
.ant-menu-submenu-popup > ul{
    margin-top: -8px;
    border-top: 1px solid rgba(0,0,0,.1);
}
h4.ant-typography.MenuTitle{
    font-size: 14px;
    line-height: 20px;
    color: var(--e-color-primary);
    font-weight: normal;
    margin: 0px 0px 30px 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(115, 115, 115, 0.2);
}
h5.ant-typography{
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
}
.MegaMenu{
    padding-bottom: 95px;
    min-height: 425px;
    cursor: auto;
}
.MenuInnerLeft{
    border: 1px solid rgba(115, 115, 115, 0.2);
    border-radius: 5px;
    padding: 15px;
    margin-left: 5px;
    width: calc(100% - 5px);
}
.MenuInnerLeft.ml-0{
    width: 100%;
}
.MegaMenu .MenuSubContent{
    font-size: 13px;
    white-space: normal;
    line-height: 24px;
    color: rgba(0,0,0,.85);
}
.MenuInnerLeft img{
    background-color:#f3ecef;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
}
.MegaMenu .ant-menu-item{
    display: flex;
    padding: 5px 50px 5px 5px;
    height: 62px;
    line-height: 52px;
    margin-top: 0px;
    margin-bottom: 20px !important;
    border-radius: 5px;
    align-items: center;
}
.MegaMenu .ant-menu-item:hover{
    background-color:rgba(0, 0, 0, 0.1);
}
.MegaMenu .ant-menu-item svg{
    /* fill: #ffffff;
    background: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%); */
}
.MegaMenu .ant-menu-item:hover svg{
    fill: var(--e-color-orange);
    background: #ffffff;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    -o-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}
.MegaMenu .ant-menu-item.whatsapp:hover svg{
    fill:#25D366 !important;
    background: #ffffff;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
    -o-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}
.MegaMenu .ant-menu-item-icon{
    border: 1px solid rgba(115, 115, 115, 0.2);
    background-color: #ffffff;
    border-radius: 5px;
    padding: 9px;
    text-align: center;
    vertical-align: middle;
    margin-left: 0px;
}
.MenuInnerCenter{
    width: 35%;
}
.MenuInnerRight{
    background-color: #f9f9f9;
}
.MenuInnerRight img{
    max-height: 150px;
    object-fit: contain;
    margin-bottom: 18px;
}
.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: #ffffff;
}
.ant-menu-horizontal>.ant-menu-item:after, .ant-menu-horizontal>.ant-menu-submenu:after{
    bottom: auto;
    top: -1px;
}
.ant-drawer-body{
    padding: 24px 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #ffffff;
}
.ant-menu-submenu-horizontal.ant-menu-submenu-selected .ant-menu-submenu-arrow{
    color: var(--e-color-orange);
}
.userButtons{
    display: flex;
    position:absolute;
    right: 17px;
    top:0px;
}
.userButtons a{
    padding: 0px 20px;
    color: #000000;
}
.userButtons a:hover::after {
    content: '';
    border-bottom: 2px solid var(--e-color-black);
    position: absolute;
    top: -1px;
    width: 54%;
    left: 20px;
}
.userButtons a:last-child{
    padding-right: 0px;
}
.signUpBtn span{
    background-color: var(--e-color-orange);
    padding: 12px 25px;
    color: #ffffff;
    border-radius: 5px;
    background-image: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
    transition: all 0.5s ease;
}
.signUpBtn:hover:after,.ant-menu-horizontal .ant-menu-item-selected.signUpBtn:after{
    border: none !important;
    border-color: #ffffff !important;
}
.signUpBtn span:hover{
    background-image: linear-gradient(90deg, var(--e-color-secondary ) 0%, var(--e-color-orange ) 100%);
}
.MenuLeftPnl{
    min-width: 285px;
}
.productsMenu li.ant-menu-item{
    padding: 0px;
    height: auto;
    width: 100%;
    margin-bottom: 0px !important;
}
.ant-layout-header.fixed{
    position: fixed;
    animation: fadeInDown 0.4s ease;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
}
.visibilityHidden{
    visibility: hidden;
    height: 0px;
}
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.MenuBottomStrip {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: grid;
    grid-template-columns: 1fr auto;
}
.stripSVG {
    display: grid;
    grid-template-columns: 1fr auto;
}
.stripSVG svg {
    grid-column: 2 / auto;
    height: 56px;
    transform: translateX(1px);
}
.stripSVG svg path {
    fill: var(--e-color-orange);
}
.stripSVGBtm {
    height: 16px;
    grid-area: 2 / span 2 / auto / auto;
    background: var(--e-color-orange);
}
.MenuStripAside {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    background:var(--e-color-orange);
    color: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 16px;
    min-width: 500px;
    height: 72px;
    background: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
    justify-content: center;
}
.MenuStripLink {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: #ffffff !important;
}
.mainMenu{
    padding-right: 90px;
}
.dropdownMenu {
    position: absolute;
    right: 0px;
    top: 64px;
    width: 215px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px #cccccc;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    visibility: hidden;
}
.dropdownMenu a{
    line-height: 50px;
}
.dropdownMenu a:hover::after{
    content: none;
}
.dropdownMenu a:hover{
    background-color: #f3f3f3;
}
.userButtons:hover .dropdownMenu{
    visibility: visible;
}
.menuLink img{
    width: 30px;
    margin-right: 7px;
}
/******responsive css******/
@media screen and (max-width: 767px){
.ant-layout-header{
    padding: 0px 15px;
    position: relative;
    z-index: 1;
}
}
@media screen and (max-width: 991px){
em {
    display: block;
    width: 22px;
    height: 3px;
    border-radius: 10px;
    background: var(--e-color-primary);
    margin-top: 4px;
    transition: transform .3s cubic-bezier(.645,.045,.355,1),opacity .3s cubic-bezier(.645,.045,.355,1);
}
em:first-child {
    margin-top: 0;
}
.ant-scrolling-effect em:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}
.ant-scrolling-effect em:nth-child(2) {
    opacity: 0;
}
.ant-scrolling-effect em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
}
.ant-menu-submenu-arrow{
    right: 24px;
    color:var(--e-color-primary);
}
.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow, 
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, .ant-menu-submenu-popup:hover>.ant-menu-submenu-arrow {
    transform:rotate(0deg) !important;  
}
.MegaMenu{
    flex-direction: column;
}
.MegaMenu .ant-menu{
    background: transparent;
}
.MenuInnerLeft, .MenuInnerRight{
    display: none;
}
.MenuInnerCenter{
    width: 100%;
    padding-top: 0px;
}
.MegaMenu .ant-menu-item{
    padding: 0px;
    margin-bottom: 5px !important;
}
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, 
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-light .ant-menu-submenu-title:hover {
    color: var(--e-color-primary);
}
.ant-menu-submenu-open:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow, 
.ant-menu-submenu-open:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
    color: var(--e-color-orange);
}
.ant-menu-submenu-open > div > .ant-menu-title-content, 
.ant-menu-submenu-open > div > .ant-menu-submenu-arrow{
    color: var(--e-color-orange);
}
.signUpBtn{
    right: 0px;
}
.signUpBtn span{
    background-color: transparent;
    background-image: none;
    padding: 0px;
    color: var(--e-color-primary);
}
.signUpBtn span:hover{
    background-color: transparent;
    color: var(--e-color-orange);
    background: none;
}
.ant-menu-inline .ant-menu-item:after{
    border-right-color:transparent;
}
.mobLogin{
    height: 40px;
    line-height: 40px;
    padding: 0px 16px 0px 24px;
    display: block;
    color: #000000;
    margin: 4px 0px;
}
.mobLogin:hover{
    color: var(--e-color-orange);
}
.signINLinks{
    padding: 15px 0px 15px;
    border-bottom: 1px solid #f0f0f0;
    width: 253px;
    margin: 0px auto 10px;
}
}