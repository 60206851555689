.sectionLeft{
    background-size: 100%;
    border-radius: 40px;
    padding: 40px;
    min-height: 550px;
}
.sectionRight{
    background-size: 100%;
    border-radius: 40px;
    padding: 40px;
    min-height: 550px;
}
h2.sectionMTitle{
    font-size: 65px;
    color: var(--e-color-blue);
    letter-spacing: 1px;
}
h2.sectionUlTitle{
    font-family: 'Poppins-Regular';
    font-size: 30px;
    margin-bottom: 70px;
    line-height: 48px;
}
h4.ant-typography{
    color: var(--e-color-black);
    font-family: 'Poppins-Regular';
    font-weight: normal;
}
.sectionUlTitle ul{
    padding-left: 0px;
}
.sectionUlTitle ul li{
    list-style: none;
    padding-left: 0px;
    margin-left: 0px;
    color: var(--e-color-primary);
}
.four-sec-img{
    border-radius: 20px;
    width: 100%;
    max-height: 230px;
    object-fit: cover;
    margin-bottom: 20px;
}
.description{
    font-size: 16px;
    line-height: 24px;
    min-height: 55px;
    margin-bottom: 20px;
    width: 600px;
}
.sectionDesc{
    font-size: 16px;
    margin-top: -40px;
    width: 70%;
}
.sectionpdDesc{
    font-size: 16px;
    margin-top: 20px;
    margin-right: 100px;
}
.parallax_w{
    max-width: 1388px;
    margin: 0px auto;
    padding: 100px 0px;
}
.Parallax_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 710px;
    /* overflow: hidden; */
}
.sticky_container{
    width: 710px;
    display: flex;
    justify-content: center;
}
.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 170px;
    width: 710px;
    height: 460px;
    background-repeat: no-repeat;
    border-radius: 0px;
    padding-right:15px;
    background-size: 100%;
    background-position: 0px;
}
.centeredTabs .ant-tabs-nav-list{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.ant-tabs-tab{
    padding: 12px 20px;
}
.ant-tabs-tab+.ant-tabs-tab{
    margin: 0px;
}
.parallax{
    display: flex;
    justify-content: center;
    height: 300vh;
}
.frame {
    z-index: 1;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.image {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: .6s;
    background-position: center;
    /* transform: scale(0.6);
    transform-origin: 100% 50%; */
}
.image_0 {
    z-index: 0;
    background-image: url("../../img/banks_nbfcs.png");
    background-color: #ffffff;
    border-radius:10px;
}
.image_1 {
    z-index: 1;
    background-image: url("../../img/channel.png");
    background-color: #ffffff;
    border-radius:10px;
}
.image_2 {
    z-index: 2;
    background-image: url("../../img/partner.png");
    background-color: #ffffff;
    border-radius:10px;
}
.image_visible {
    opacity: 1;
    right: 0px;
    /* transform: scale(1); */
    /* transition: all 0.5 ease;
    transform: rotateZ(-40%); */
}
.h100{
    height: 100vh;
    width: 100%;
    /* transform: translateY(0px) !important;*/
}
.pl15{
    padding-left: 0px;
}
.sliderImg{
    width: 100%;
    position: relative;
    margin-right: -30px;
}
span.sliderTXT {
    position: absolute;
    color: #fff;
    padding: 39px;
    left: 0px;
    top: 0px;
    text-align: center;
    width: 100%;
}
.slideshow {
    margin-right:-16px;
    overflow: hidden;
} 
.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
} 
.slide {
    display: inline-block;
}
.description ul li{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 10px;
}
.sectionpdDesc ul li {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 5px;
}
.sliderPnl{
    padding:180px 0px 100px 0px;
}
.pdImg{
    border-radius: 30px;
    width: 100%;
}
.content-pd-lft{
    padding-left: 100px !important;
}
.w-25{
    width: 25%;
}
h4.small_description{
    max-width: 700px;
    margin: 0px auto !important;
    text-align: center;
}
.datapnl_inner{
    /* background-color: #f5f5f5; */
    padding: 30px 50px;
    position: relative;
}
.datapnl_inner::after{
    content: '';
    position: absolute;
    width: 2px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    right: 0px;
    top: 40px;
    border-radius: 10px;
}
.datapnl_inner:first-child{
    border-radius: 10px 0px 0px 10px;
}
.datapnl_inner:last-child{
    border-radius: 0px 10px 10px 0px;
}
.datapnl_inner:last-child::after{
    content: none;
}
.dataPnl{
    margin-bottom: 100px;
}
h4.data_head{
    font-size: 16px;
}
.data{
    font-size: 32px;
    font-weight: bold;
}
@media screen and (max-width:360px){
.datapnl_inner{
    width: 88%;
}
.datapnl_inner::after{
    content: none;
}
}
@media screen and (max-width:767px) {
.sliderPnl{
    padding: 50px 20px 20px;
    align-items: initial;
}
.d-none-xs{
    display: none;
}
span.animated{
    width: 100%;
}
.sectionpdDesc{
    margin-right: 0px;
    margin-bottom: 20px;
}
.pdImg{
    border-radius: 10px;
}
.order-xs-2{
    order: 2;
}
.mb-80{
    margin-bottom: 50px !important;
}
.centeredTabs .ant-tabs-nav-list{
    width: 100%;
}
.content-pd-lft{
    padding-left: 0px !important;
}
.w-25{
    width: 100%;
}
.ant-tabs-tab{
    padding: 12px 14px;
}
.parallax-mob h3.sectionTitle {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 0px;
}
.products h4.ant-typography{
    margin-top: -15px;
    margin-bottom: -5px;
}
.cust-str .ant-layout-content{
    padding-top: 0px;
}
.description{
    min-height: auto;
}
.dataPnl{
   flex-direction: column;
    width: 100%;
    margin: 0px auto;
}
.datapnl_inner{
    width: 100%;
    padding: 15px;
    text-align: center;
}
h4.data_head{
    font-size:15px;
}
.datapnl_inner::after{
    content: none;
}
.datapnl_inner:first-child{
    border-radius: 10px 0px 0px 0px;
}
.datapnl_inner:nth-child(2){
    border-radius: 0px 10px 0px 0px;
}
.datapnl_inner:nth-child(5){
    border-radius: 0px 0px 0px 10px;
}
.datapnl_inner:last-child{
    border-radius: 0px 0px 10px 0px;
}
.sectionUlTitle ul li{
    font-size: 28px;
}
}