@import 'antd/dist/antd.min.css';
@font-face {
  font-family: Poppins-Regular;
  src: url(./fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-medium;
  src: url(./fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins-bold;
  src: url(./fonts/Poppins-Bold.ttf);
}
body {
  margin: 0;
  font-family:'Poppins-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  --e-color-primary: #000000;
  --e-color-secondary: #fe52ae;
  --e-color-black: #000000;
  --e-color-orange: #ea0681;
  --e-color-blue: #273896;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.d-flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-space-between{
  justify-content: space-between;
}
.d-none{
  display: none;
}
.sliderArea{
  padding: 120px 100px 150px;
}
.blue-bg{
  background-color: #273896;
  color: #ffffff;
}
.blue{
  color:var( --e-color-primary );
}
.ml-0{
  margin-left: 0px !important;
}
.mt-20{
  margin-top: 20px;
}
.mt-50{
  margin-top: 50px !important;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-80{
  margin-bottom: 80px !important;
}
.mb-150{
  margin-bottom: 150px !important;
}
.ant-row.mb-150{
  margin-bottom: 150px !important;
}
.ant-row.mt-50{
  margin-top: 50px !important;
}
.pad20{
  padding: 20px 20px 0px 20px;
}
.w-100{
  width: 100%;
  max-width: 100% !important;
}
.relative{
  position: relative;
}
.btn-txt{
  color: var(--e-color-black);
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
}
.btn-txt:hover{
  color: var(--e-color-orange);
  border-color: var(--e-color-orange);
}
.btn-inline-txt{
  width: 86px;
  margin: 20px auto 0px;
}
.ant-layout-content.pagePadding{
  padding: 50px;
}
.pulse {
  animation: pulse infinite ease-in-out alternate;
}
@keyframes pulse {
  0% { opacity:0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px);}
  25% { opacity:1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px);}
  50% {opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px);}
  100% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px);}
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Poppins-Regular';
}
h1.mainTitle{
  font-size: 70px;
  margin-bottom: 0px;
  display: inline-block;
  overflow: hidden;
  line-height: 85px;
  width: 1155px;
  color: var( --e-color-primary );
  letter-spacing: 1px;
}
h2.sectionTitle{
  font-size: 55px;
  margin-bottom: 50px;
  line-height: 65px;
  color: var( --e-color-primary );
  letter-spacing: 1px;
}
h3.sectionTitle{
  font-size: 40px;
  margin-bottom: 20px;
  line-height: 40px;
  color: var( --e-color-primary );
  letter-spacing: 1px;
}
h4{
  font-size: 15px;
}
h4.sectionSubTitle.ant-typography{
  margin-top: 0px;
}
.grey{
  background-color: #f5f5f5;
}
.white{
  background-color: #ffffff;
}
.white-grad{
  background:linear-gradient(#ffffff, #f5f5f5);;
}
.ant-layout-content{
  padding: 100px 50px;
}
.animated {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode:forwards;
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  display: inline-block;
  animation-iteration-count: infinite;
  color:var(--e-color-orange);
  background: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes fadeInUp {
  0% {transform: translateY(100%); opacity: 0.5;}
  30% {transform: translateY(0%); opacity: 1;}
  50% {transform: translateY(0%); opacity: 1;}  
  70% {transform: translateY(0%); opacity: 1;}
  90% {transform: translateY(0%); opacity: 1;}
  100% {transform: translateY(0%); opacity: 0;}
}
@-webkit-keyframes fadeInUp {
  0% {transform: translateY(100%); opacity: 0.5;}
  30% {transform: translateY(0%); opacity: 1;}
  50% {transform: translateY(0%); opacity: 1;}  
  70% {transform: translateY(0%); opacity: 1;}
  90% {transform: translateY(0%); opacity: 1;}
  100% {transform: translateY(0%); opacity: 0;}
}
.btn-orange{
  background-color:var(--e-color-orange);
  background: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
  color: #ffffff;
  min-width: 148px;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.btn-orange:hover{
  color: #ffffff;
  background: linear-gradient(90deg, var(--e-color-secondary) 0%, var(--e-color-orange ) 100%);
  border: none;
}
.btn-white{
  background-color: transparent;
  color: #ffffff;
  min-width: 140px;
  height: 45px;
  font-size: 16px;
  line-height: 22px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 20px;
  background-image: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
}
.btn-white:hover{
  color: #ffffff;
  background-image: linear-gradient(90deg, var(--e-color-secondary ) 0%, var(--e-color-orange ) 100%);
}
.overflow-hidden{
  overflow: hidden;
}
.fullViewBg .ant-layout-content{
  background-size: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  height: 93vh;
  align-items: center;
  display: flex;
}
.fullViewBg .sectionTitle{
  color: #000000;
}
.br12{
  border-radius: 12px;
  box-shadow: 0 0px 2px 0 rgb(0 0 0 / 25%);
}
.Titledescription{
  font-size: 20px;
  color: var(--e-color-black);
  margin-top: -30px;
  line-height: 16px;
  min-height: 16px;
  margin-bottom: 18px;
}
/*---------contact----------*/
.form input, 
.form textarea, 
.form select {
  width: 100%;
  padding: 10px;
  border:1px solid rgba(0, 0, 0, 0.1);
}
.w-49{
  width: 49%;
}
p.error_messages {
  color: #ff0000;
  font-size: 13px;
  margin-top: 5px;
}
.contactDesc{
  width: 100%;
  margin: -40px 0px 40px;
  text-align: left;
  line-height: 25px;
}
.contact .item{
  display: flex;
}
.contact .cont{
  margin-left: 10px;
}
.contact h4.ant-typography {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contact h5.ant-typography {
  font-size: 16px;
}
.contact p{
  color: #999999;
  margin-bottom: 30px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, 
.ant-tabs-tab:hover{
  color: var( --e-color-orange );
}
.ant-tabs-ink-bar{
  background: var( --e-color-orange );
}
.ant-tabs-tab{
  font-size: 16px;
}
.contactRightPnl{
  padding: 0px;
}
/*---------contact----------*/
/****distributor page css*/
.custom_carousel {
  min-height: 100vh;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.tab_wrap{
  margin-right: 0px;
  padding: 100px 66px 100px 0px;
}
.content_wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
}
.tab{
  position: relative;
  display: block;
  height: 70px;
  padding: 20px 0px;
  transition: height 0.15s ease-in;
  color: #969696;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
  max-width: 400px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.tab .post_title{
  color: #969696;
  margin-bottom: 5px;
}
.Tabtag svg{
  fill: #747474;
} 
.tab.active .Tabtag svg{
  fill: #000000;
}
.tab:hover svg{
  fill: var(--e-color-orange);
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}
.Tabtag svg.whatsapp{
  width: 18pt;
  height: 40pt;
}
.tab:hover svg.whatsapp{
  fill: #25D366;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}
.tab:hover .post_title{
  color: #000;
}
.tab.active{
  height: auto;
  transition: height 0.15s ease-in;
  color: #333333;
}
.tab.active .post_title{
  color: #333333;
}
.post_content{
  height: 0px;
  visibility: hidden;
  display: block;
}
.tab.active .post_content{
  visibility: visible;
  height: auto;
  transition: height 0.15s ease-in;
}
.Tabtag {
  line-height: 0px;
  margin-right: 20px;
}
.Tabtag svg{
  width: 25px;
  margin-top: -10px;
}
.tab_content{
  padding: 0px 50px;
}
/****supply page css*/
.imgOverlay {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  background-color: rgba(0,0,0,0.5);
  border-radius: 0 0px 12px 12px;
  padding: 8px;
  color: #fff;
  font-size: 24px;
}
/* .case_study{
  display: inline-block;
  width: 25%;
} */

/***How it Works*/
.HowItWorks{
  position: relative;
  z-index: 4;
  text-align:center;
}
.HowItWorks img {
  position: absolute;
  width: 70%;
  right: -40%;
  top: 15px;
  opacity: .5;
}
img.tobotm {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.HowItWorks .icon {
  color: #2576FD;
  font-size: 30px;
  width: 80px;
  height: 80px;
  line-height: 85px;
  border-radius: 50%;
  border: 1px dashed #eee;
  margin-bottom: 30px;
}
.HowItWorks .cont {
  padding: 50px 30px 30px 30px;
  background-color: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  z-index: 3;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.HowItWorks h3.ant-typography {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  color: #777;
  font-size: 65px;
  font-weight: bold;
  opacity: .1;
  z-index: -1;
}
.HowItWorks h5.ant-typography {
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 0px;
}
.HowItWorks p {
  font-size: 14px;
  color: #999;
}
.HowItWorks .anticon{
  font-size: 30px;
  width: 80px;
  height: 80px;
  line-height: 85px;
  border-radius: 50%;
  border: 1px dashed #d4d4d4;
  margin-bottom: 30px;
}
.callToAction .grey{
  background-color: #ffffff;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 6px 11px;
  height: auto;
}
.ant-select-arrow .anticon{
  font-size: 19px;
  margin: 0px 0px 0px 0px;
}
.calculator-form .ant-form-item{
  margin-bottom: 15px;
}
.calculator-form button{
  margin-top: 10px;
}
h3.pageTitle{
  color: var(--e-color-primary);
  margin-bottom: 30px;
}
.ant-btn-primary:active, .ant-btn-primary:focus, .ant-btn-primary:hover{
  background-color: var(--e-color-orange);
  border-color: var(--e-color-orange);
  background: linear-gradient(90deg, var(--e-color-secondary) 0%, var(--e-color-orange ) 100%);
}
.solutionsMenu{
  display: flex;
  position: absolute;
  top: 30px;
  width: 450px;
}
.solutionsMenu li {
  border: 1px solid #cccccc;
  color: #999;
  text-align: center;
  margin-right: 10px;
  border-radius: 3px;
  margin-bottom: 0px !important;
  line-height: 35px !important;
}
.solutionsMenu li.active, .solutionsMenu li:hover {
  border-color:#ffffff;
  color: #ffffff;
  background: linear-gradient(90deg, var(--e-color-orange) 0%, var(--e-color-secondary ) 100%);
}
.solutionsMenu li:hover span{
  color: #ffffff;
}
.ant-tabs-top>.ant-tabs-nav:before{
  border-bottom: none;
}
.ant-input:hover, .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: var(--e-color-secondary);
}
h4.ant-typography.aboutUS{
  display:inline-block;
  background-color:#fb506c; 
  padding:8px 20px; 
  border-radius: 5px; 
  color: #fff; 
  font-Size: 15px;
  margin-bottom: 15px;
}
/***products page css*/
.productsPNL{
  flex: 1;
  margin: 0px 15px;
  box-shadow: 0 0px 2px 0 rgb(0 0 0 / 25%);
  padding: 18px 18px 8px;
  border-radius: 12px;
  background-color: #ffffff;
}
.productsPNL img{
  height: 75px;
  width: auto;
  margin-bottom: 25px;
}
.LoanResultsPnl{
  display: flex;
  margin: 0px -15px 20px;
}
.loanProductsPNL{
  flex: 1;
  margin: 0px 15px;
  box-shadow: 0 0px 2px 0 rgb(0 0 0 / 25%);
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
}
.loanProductsPNL .anticon {
  font-size: 45px;
  margin-left: 0px;
  margin-bottom: 10px;
}
.success-loan{
  background-color: #008000;
  color: #ffffff;
}
.loanResult{
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 15px;
}
.failure-loan{
  background-color: #e30101;
  color: #ffffff;
}
.loanProductsPNL.failure-loan .anticon{
  margin-bottom: 0px;
  margin-right: 10px;
} 
.text-justify{
  text-align: justify;
}
.privacy-content p{
  text-align: justify;
}
.justify-content-end{
  justify-content: end;
}
.d-visible-xs{
  display: none;
}
.loopImg{
  width: 55%;
}
.mb-40{
  margin-bottom: 40px !important;
}
.extLink{
  /* border: 1px solid #ccc; */
  padding: 25px;
  border-radius: 5px;
  align-items: center;
  display: inline-flex;
  width: 31.3%;
  font-size: 22px;
  color: #000;
  margin: 2% 1% 0px 1%;
  text-align: left;
  max-height: 105px;
}
.extLink svg{
  margin-right: 15px;
  width: 40px;
}
.extLink:hover{
  background-color: rgba(0, 0, 0, 0.05);
  color: #000;
}
.extLink:hover svg{
  fill: var(--e-color-orange);
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}
.sectionAlignRight{
  text-align: right;
}
.abtSec{
  border: 1px solid #ccc;
  padding: 25px;
  border-radius: 5px;
  display: inline-flex;
  width: 100%;
  font-size: 22px;
  color: #000;
  margin: 2% 2% 0px 0px;
  text-align: left;
  flex-direction: column;
}
.abtSec .description, .abtSec .description ul {
  width: 100%;
  margin-bottom: 0px;
}
.height50{
  height: 50px;
}
.blue-bg h2.sectionTitle{
  color: #ffffff;
}
.page_description{
  margin-top: -20px;
}
.page_description ul.page_ul{
  margin: 0px auto;
  text-align: center;
}
.page_description ul li{
  background-color: #f2f2f2;
  display: inline-flex;
  padding: 5px 10px;
  border-bottom: 10px;
  margin: 0px 5px;
}
.footer_logos img{
  display: inline-flex;
  max-width: 300px;
  margin: 10px 25px;
}
.smWidth{
  max-width: 350px;
  width: 100%;
}
.globe{
  width: 80%;
  margin: 0px auto;
}
.globe-sm{
  width: 70%;
  margin: 0px auto;
}
@media (max-width: 768px){
  .d-none-xs{
    display: none !important;
  }
  .d-visible-xs{
    display: block;
  }
  h1.mainTitle{
    font-size: 55px;
    line-height: 65px;
    width: 100%;
  }
  h1 span{
    display: block;
  }
  h2.sectionTitle {
    font-size: 38px;
    margin-bottom: 15px;
    line-height: 40px;
  }
  h3.sectionTitle{
    font-size: 35px;
    line-height:40px;
  }
  .Titledescription{
    margin-top: -5px;
    margin-bottom: 25px;
  }
  .white .ant-col, .grey .ant-col{
    margin-bottom: 20px;
  }
  .ant-layout-content, .ant-layout-content.pagePadding{
    padding: 50px 15px;
  }
  .fullViewBg .ant-layout-content {
    background-size: 150%;
    background-repeat: no-repeat;
    object-fit: cover;
    height: 245px;
    align-items: center;
    background-position: right;
    display: flex;
  }
  .fullViewBg .ant-layout-content{
    position: relative;
  }
  .fullViewBg .ant-layout-content::before{
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
  }
  .sliderArea {
    padding: 120px 15px 150px;
    justify-content: left;
  }
  .flex-xs-column{
    flex-direction: column;
  }
  .ant-row.mb-150{
    margin-bottom: 30px !important;
  }
  .contactRightPnl {
    padding: 0px;
    margin-top: -20px;
  }
  .w-49{
    width: 48%;
  }
  .calculator-form .ant-col{
    margin-bottom: 0px;
  }
  .text-xs-center{
    text-align: center;
  }
  .contactRightPnl .ant-form-item{
    margin-bottom: 0px;
  }
  .custom_carousel {
    flex-direction: column;
  }
  .tab_wrap{
    padding: 90px 15px 30px;
  }
  .solutionsMenu{
    top: 20px;
    width: 95%;
  }
  .solutionsMenu li{
    font-size: 12px;
  }
  .btn-white{
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .contactDesc{
    margin: 0px 0px 20px;
  }
  .productsPNL{
    flex: auto;
    width: 97%;
    margin: 10px auto;
  }
  .loanProductsPNL{
    flex: auto;
    width: 92%;
    margin: 10px auto;
  }
  .tab_content{
    padding: 0px;
  }
  .loopImg{
    width: 100%;
    margin-top: 25px;
  }
  .LoanResultsPnl{
    margin-bottom: 0px;
  }
  .mt-xs-10{
    margin-top: 10px;
  }
  .page_description{
    margin-top: 0px;
  }
  .page_description ul.page_ul{
    padding-left: 0px;
  }
  .page_description ul li{
    margin: 5px;
  }
  .extLink{
    font-size: 18px;
    max-height: 85px;
    margin: 0px;
    width: 100%;
    padding: 0px 0px 0px 25px;
  }
  .extLink svg{
    width: 30px;
  }
  .mt-xs-15{
    margin-top: 15px;
  }
  .mt-xs-0{
    margin-top: 10px !important;
    margin-bottom: -60px;
  }
  .footer_logos{
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .footer_logos img{
    width: 35%;
  }
  .globe, .globe-sm{
    width: 100%;
  }
  
}
@media (min-width: 768px) and (max-width: 991px){
  .d-visible-sm{
    display: block;
  }
  .d-none-sm{
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1440px){
  h1.mainTitle {
    width: 100%;
    max-width: 1140px;
  }
  .sliderPnl {
    padding: 160px 60px !important;
  }
  .Parallax_content, .sticky_container{
    width: 50% !important;
  }
  .parallax{
    padding:0px 60px;
  }
  .parallax_w{
    width: 100%;
  }
}